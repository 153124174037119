import {
  all,
  call,
  delay,
  put,
  takeEvery,
  select
} from 'redux-saga/effects';
import * as actions from './actions';
import * as actionTypes from './types';
import {
  get,
  put as axiosPut,
  post,
  apiDelete
} from '../../utils/api';
import { popupLoaderClose, popupLoaderOpen } from '../loader/actions';
import { errorModal, openNotificationWithIcon } from '../../utils/function';
import {
  NEWS_LIST,
  NEWS,
  NEWS_PUBLISH,
  NEWS_PICTURE,
  PICTURE, NEWS_ON_ORGANIZATION, NEWS_UNPUBLISH
} from '../../utils/url';

function* getListNewsOnOrganization({ payload }) {
  if (payload.isSearch) {
    yield put(popupLoaderClose());
    yield delay(1000);
  }
  try {
    yield put(popupLoaderOpen());
    let response = yield call(
      get,
      // eslint-disable-next-line max-len
      `${NEWS_LIST(payload.organizationUuid)}?offset=${payload.offset}&limit=${payload.limit}${payload.search ? `&search=${encodeURI(payload.search)}` : ''}`
    );
    let offset = payload?.offset ? payload?.offset : 0;
    if (response?.rows && response?.rows.length === 0 && payload.offset > 0) {
      offset -= payload.limit;
      response = yield call(
        get,
        // eslint-disable-next-line max-len
        `${NEWS_LIST(payload.organizationUuid)}?offset=${offset}&limit=${payload.limit}${payload.search ? `&search=${encodeURI(payload.search)}` : ''}`
      );
    }
    yield put(actions.getListNewsOnOrganizationSuccess({
      ...response,
      limit: payload.limit,
      search: payload.search,
      current: Math.round(offset / payload.limit) + 1
    }));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}
function* getListNewsOnOrganizationDop({ payload }) {
  if (payload.isSearch) {
    yield put(popupLoaderClose());
    yield delay(1000);
  }
  try {
    yield put(popupLoaderOpen());
    let response = yield call(
      get,
      // eslint-disable-next-line max-len
      `${NEWS_LIST(payload.organizationUuid)}?offset=${payload.offset}&limit=${payload.limit}${payload.search ? `&search=${encodeURI(payload.search)}` : ''}`
    );
    let offset = payload?.offset ? payload?.offset : 0;
    if (response?.rows && response?.rows.length === 0 && payload.offset > 0) {
      offset -= payload.limit;
      response = yield call(
        get,
        // eslint-disable-next-line max-len
        `${NEWS_LIST(payload.organizationUuid)}?offset=${offset}&limit=${payload.limit}${payload.search ? `&search=${encodeURI(payload.search)}` : ''}`
      );
    }
    yield put(actions.getListNewsOnOrganizationDopSuccess({
      ...response,
      limit: payload.limit,
      search: payload.search,
      current: Math.round(offset / payload.limit) + 1
    }));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* updateListNewsOnOrganization({ organizationUuid }) {
  const { news } = yield select(({ newsReducer }) => newsReducer);
  yield put(actions.getListNewsOnOrganization({
    search: news.pagination.search && news.pagination.search.length >= 3,
    offset: ((news.pagination.current ? news.pagination.current : 1) - 1) * news.pagination.pageSize,
    limit: news.pagination.pageSize,
    organizationUuid
  }));
}
function* updateListNewsOnOrganizationDop({ organizationUuid }) {
  const { newsDop } = yield select(({ newsReducer }) => newsReducer);
  yield put(actions.getListNewsOnOrganizationDop({
    search: newsDop.pagination.search && newsDop.pagination.search.length >= 3,
    offset: ((newsDop.pagination.current ? newsDop.pagination.current : 1) - 1) * newsDop.pagination.pageSize,
    limit: newsDop.pagination.pageSize,
    organizationUuid
  }));
}

function* createNewsOnOrganization({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      post,
      NEWS(payload.organizationUuid),
      {
        title: payload.title ? payload.title : null,
        text: payload.text ? payload.text : null
      }
    );
    yield put(popupLoaderClose());
    openNotificationWithIcon(
      'success',
      'Новость создана',
      null
    );
    yield all([
      updateListNewsOnOrganization({ organizationUuid: payload.organizationUuid }),
      updateListNewsOnOrganizationDop({ organizationUuid: payload.organizationUuid })
    ]);
    yield put(actions.closeModalCreateNews());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}
function* editNewsOnOrganization({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      axiosPut,
      NEWS_ON_ORGANIZATION(payload.organizationUuid, payload.newsUuid),
      {
        title: payload.title ? payload.title : null,
        text: payload.text ? payload.text : null
      }
    );
    yield put(popupLoaderClose());
    openNotificationWithIcon(
      'success',
      'Данные обновлены',
      null
    );
    if (payload.setKeyRowExpanded) {
      payload.setKeyRowExpanded((values) => {
        let temp = values;
        if (temp.indexOf(payload.newsUuid) === -1) {
          temp.push(payload.newsUuid);
        } else {
          temp = temp.filter((item) => item !== payload.newsUuid);
        }
        return temp;
      });
    }
    yield all([
      updateListNewsOnOrganization({ organizationUuid: payload.organizationUuid }),
      updateListNewsOnOrganizationDop({ organizationUuid: payload.organizationUuid })
    ]);
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}
function* deleteNewsOnOrganization({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      apiDelete,
      NEWS_ON_ORGANIZATION(payload.organizationUuid, payload.newsUuid)
    );
    yield put(popupLoaderClose());
    openNotificationWithIcon(
      'success',
      'Новость успешно удалена',
      null
    );
    yield all([
      updateListNewsOnOrganization({ organizationUuid: payload.organizationUuid }),
      updateListNewsOnOrganizationDop({ organizationUuid: payload.organizationUuid })
    ]);
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}
function* publishNewsOnOrganization({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      axiosPut,
      NEWS_PUBLISH(payload.organizationUuid, payload.newsUuid)
    );
    yield put(popupLoaderClose());
    openNotificationWithIcon(
      'success',
      'Новость успешно опубликована',
      null
    );
    yield all([
      updateListNewsOnOrganization({ organizationUuid: payload.organizationUuid }),
      updateListNewsOnOrganizationDop({ organizationUuid: payload.organizationUuid })
    ]);
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}
function* unpublishNewsOnOrganization({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      axiosPut,
      NEWS_UNPUBLISH(payload.organizationUuid, payload.newsUuid)
    );
    yield put(popupLoaderClose());
    openNotificationWithIcon(
      'success',
      'Новость успешно опубликована',
      null
    );
    yield all([
      updateListNewsOnOrganization({ organizationUuid: payload.organizationUuid }),
      updateListNewsOnOrganizationDop({ organizationUuid: payload.organizationUuid })
    ]);
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}
// eslint-disable-next-line
export default function* () {
  yield all([
    takeEvery(actionTypes.GET_LIST_NEWS_ON_ORGANIZATION, getListNewsOnOrganization),
    takeEvery(actionTypes.GET_LIST_NEWS_ON_ORGANIZATION_DOP, getListNewsOnOrganizationDop),
    takeEvery(actionTypes.CREATE_NEWS_ON_ORGANIZATION, createNewsOnOrganization),
    takeEvery(actionTypes.EDIT_NEWS_ON_ORGANIZATION, editNewsOnOrganization),
    takeEvery(actionTypes.DELETE_NEWS_ON_ORGANIZATION, deleteNewsOnOrganization),
    takeEvery(actionTypes.PUBLISH_NEWS_ON_ORGANIZATION, publishNewsOnOrganization),
    takeEvery(actionTypes.UNPUBLISH_NEWS_ON_ORGANIZATION, unpublishNewsOnOrganization)
  ]);
}
