import React from 'react';
import { notification, Modal } from 'antd';
import truncate from 'html-truncate';

export const renderHeaderTitleTable = (
  title,
  subtitle
) => (
  <>
    <span className="dd-table-header-title">{title}</span>
    {subtitle && (<span className="dd-table-header-subtitle">{subtitle}</span>)}
  </>
);

export const openNotificationWithIcon = (type, message, description, duration = 4.5) => {
  notification[type]({
    duration,
    message,
    description
  });
};

export function errorModal(
  title = 'Ошибка',
  content = 'Неизвестная ошибка, попробуйте чуть позже',
  dopText = null
) {
  notification.error({
    duration: 0,
    message: title || 'Ошибка',
    description: dopText ? (
      <>
        {content || 'Неизвестная ошибка, попробуйте чуть позже'}
        <br />
        <br />
        <div>
          { typeof dopText === 'object' && JSON.stringify(dopText)}
        </div>
      </>
    ) : content || 'Неизвестная ошибка, попробуйте чуть позже'
  });
}

export function randomCode() {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 8; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function renderFIO(text, record, index) {
  return (
    <span style={record?.employee?.acceptedAt ? { } : { fontWeight: 'bold' }}>
      {record?.lastName}
      <br />
      {record?.firstName}
      <br />
      {record?.middleName}
    </span>
  );
}
export function renderFIOAndRole(text, record, index) {
  return (
    <span>
      {`${record?.lastName} ${record?.firstName} ${record?.middleName ? record?.middleName : ''}`}
      <br />
      <small>{record?.role?.title}</small>
    </span>
  );
}
export function renderLoginEmail(text, record, index) {
  return (
    <span>
      {record?.login}
      <br />
      <small>{record?.email}</small>
    </span>
  );
}
export function renderOrganization(text, record, index) {
  return (
    <span>
      {record?.organization?.title}
    </span>
  );
}

export function renderDepartment(text, record, index) {
  return (
    <span>
      {record?.employee?.department}
    </span>
  );
}
export function renderStatus(text, record, index) {
  return (
    <span>
      {record?.employee?.status}
    </span>
  );
}
export function renderNewsTitle(text, record, index) {
  return (
    <span>
      <strong>
        {truncate(record?.title, 100)}
      </strong>
    </span>
  );
}
export function renderNewsPictureThumb(text, record, index) {
  return (
    <img style={{
      height: '100px',
      padding: '5px',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto'
    }} src={record?.pictureUUID
        ? `api/pictures/v1/picture/${record.pictureUUID}`
        : '/pictures/eventsProperty-211.svg'} alt={''} />
  );
}
