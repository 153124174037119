import { connect } from 'react-redux';
import FormCreatingOrEditNews from './FormCreatingOrEditNews';
import {
  createNewsOnOrganization,
  deleteNewsOnOrganization,
  editNewsOnOrganization,
  closeModalCreateNews,
  publishNewsOnOrganization, unpublishNewsOnOrganization
} from '../../store/news/actions';

const mapStateToProps = ({
  newsReducer: {
    selectOrganization,
    isModalVisible,
    isModalVisibleCreateNews
  }
}) => ({
  selectOrganization,
  isModalVisible,
  isModalVisibleCreateNews
});
const mapDispatchToProps = {
  createNewsOnOrganization,
  editNewsOnOrganization,
  deleteNewsOnOrganization,
  publishNewsOnOrganization,
  unpublishNewsOnOrganization,
  closeModalCreateNews
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormCreatingOrEditNews);
