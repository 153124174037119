import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
  useLayoutEffect
} from 'react';
import {
  Input,
  Space,
  Table,
  Button
} from 'antd';
import { format } from 'date-fns';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import style from './tablenews.module.scss';
import FormCreatingOrEditNews from '../FormCreatingOrEditNews';
import {
  renderNewsPictureThumb,
  renderNewsTitle
} from '../../utils/function';

const columnsOnForm = [
  {
    width: 60,
    title: '',
    dataIndex: '',
    render: renderNewsPictureThumb
  },
  {
    width: 60,
    title: 'Дата создания',
    dataIndex: '',
    render: (text, record, index) => record?.createdAt && format(new Date(record?.createdAt), 'dd.MM.yyyy HH:mm')
  },
  {
    width: 190,
    title: 'Заголовок',
    dataIndex: '',
    render: renderNewsTitle
  },
  {
    width: 60,
    title: 'Дата последнего изменения',
    dataIndex: '',
    render: (text, record, index) => record?.updatedAt && format(new Date(record?.updatedAt), 'dd.MM.yyyy HH:mm')
  },
  {
    width: 60,
    title: 'Дата публикации',
    dataIndex: '',
    render: (text, record, index) => record?.publishedAt && format(new Date(record?.publishedAt), 'dd.MM.yyyy HH:mm')
  }
];
const columnsOnModal = [
  {
    width: 60,
    title: '',
    dataIndex: '',
    render: renderNewsPictureThumb
  },
  {
    width: 60,
    title: 'Дата создания',
    dataIndex: '',
    render: (text, record, index) => record?.createdAt && format(new Date(record?.createdAt), 'dd.MM.yyyy HH:mm')
  },
  {
    width: 190,
    title: 'Заголовок',
    dataIndex: '',
    render: renderNewsTitle
  },
  {
    width: 60,
    title: 'Дата последнего изменения',
    dataIndex: '',
    render: (text, record, index) => record?.updatedAt && format(new Date(record?.updatedAt), 'dd.MM.yyyy HH:mm')
  },
  {
    width: 60,
    title: 'Дата публикации',
    dataIndex: '',
    render: (text, record, index) => record?.publishedAt && format(new Date(record?.publishedAt), 'dd.MM.yyyy HH:mm')
  }
];
const columnsOnModalDop = [
  {
    width: 60,
    title: '',
    dataIndex: '',
    render: renderNewsPictureThumb
  },
  {
    width: 60,
    title: 'Дата создания',
    dataIndex: '',
    render: (text, record, index) => record?.createdAt && format(new Date(record?.createdAt), 'dd.MM.yyyy HH:mm')
  },
  {
    width: 190,
    title: 'Заголовок',
    dataIndex: '',
    render: renderNewsTitle
  },
  {
    width: 60,
    title: 'Дата последнего изменения',
    dataIndex: '',
    render: (text, record, index) => record?.updatedAt && format(new Date(record?.updatedAt), 'dd.MM.yyyy HH:mm')
  },
  {
    width: 60,
    title: 'Дата публикации',
    dataIndex: '',
    render: (text, record, index) => record?.publishedAt && format(new Date(record?.publishedAt), 'dd.MM.yyyy HH:mm')
  }
];

const dataFilter = {
  search: null,
  offset: 0,
  limit: 10
};

const dataFilterDop = {
  search: null,
  offset: 0,
  limit: 10
};

const TableNews = ({
                       data,
                       pagination,
                       dataDop,
                       paginationDop,
                       organizationUuid,
                       getListNewsOnOrganization,
                       getListNewsOnOrganizationDop,
                       isModal,
                       isModalVisible
                     }) => {
  const [keyRowExpanded, setKeyRowExpanded] = useState([]);
  const [search, setSearch] = useState();
  const [searchDop, setSearchDop] = useState();
  const [modalColumns, setModalColumns] = useState(true);

  useEffect(() => {
    if (organizationUuid && !isModal) {
      getListNewsOnOrganizationDop({
        ...dataFilterDop,
        organizationUuid
      });
    }
  }, [getListNewsOnOrganizationDop, organizationUuid, isModal]);

  useEffect(() => {
    if (organizationUuid && isModal) {
      getListNewsOnOrganization({
        ...dataFilter,
        organizationUuid
      });
    }
  }, [getListNewsOnOrganization, organizationUuid, isModal]);

  useEffect(() => {
    if (!isModalVisible && isModal) {
      setSearch(null);
    }
  }, [isModalVisible, isModal]);

  const handleTableChange = useCallback((pagin, filters, sorter) => {
    if (!isModal) {
      getListNewsOnOrganizationDop({
        search: searchDop && searchDop.length >= 3,
        offset: (pagin.current - 1) * pagin.pageSize,
        limit: pagin.pageSize,
        organizationUuid
      });
    } else {
      getListNewsOnOrganization({
        search: search && search.length >= 3,
        offset: (pagin.current - 1) * pagin.pageSize,
        limit: pagin.pageSize,
        organizationUuid
      });
    }
  }, [
    isModal,
    getListNewsOnOrganizationDop,
    searchDop,
    organizationUuid,
    getListNewsOnOrganization,
    search
  ]);

  const onExpand = useCallback((expanded, record) => {
    setKeyRowExpanded((values) => {
      let temp = values;
      if (temp.indexOf(record.uuid) === -1) {
        temp.push(record.uuid);
      } else {
        temp = temp.filter((item) => item !== record.uuid);
      }
      return temp;
    });
  }, [setKeyRowExpanded]);

  const renderTable = useMemo(() => (
    // eslint-disable-next-line no-nested-ternary
    <Table columns={isModal ? (modalColumns ? columnsOnModal : columnsOnModalDop) : columnsOnForm}
           rowKey={(record) => record.uuid}
           dataSource={isModal ? data : dataDop}
           pagination={isModal ? pagination : paginationDop}
           onChange={handleTableChange}
           scroll={{ y: '100%' }}
           expandable={{
             expandedRowKeys: keyRowExpanded,
             expandedRowRender: isModal && ((record) => (
               <FormCreatingOrEditNews record={record} isTable setKeyRowExpanded={setKeyRowExpanded} />
             )),
             onExpand
           }}
    />
  ), [
    isModal,
    modalColumns,
    data,
    pagination,
    handleTableChange,
    dataDop,
    paginationDop,
    onExpand,
    keyRowExpanded,
    setKeyRowExpanded
  ]);

  const onChangeSearch = useCallback((e) => {
    if (isModal) {
      setSearch(e.target.value);
    } else {
      setSearchDop(e.target.value);
    }
    if (e.target.value.length >= 3) {
      if (isModal) {
        getListNewsOnOrganization({
          search: e.target.value,
          offset: 0,
          limit: pagination.pageSize,
          organizationUuid,
          isSearch: true
        });
      } else {
        getListNewsOnOrganizationDop({
          search: e.target.value,
          offset: 0,
          limit: paginationDop.pageSize,
          organizationUuid,
          isSearch: true
        });
      }
    } else if (isModal) {
      getListNewsOnOrganization({
        search: null,
        offset: 0,
        limit: pagination.pageSize,
        organizationUuid,
        isSearch: true
      });
    } else {
      getListNewsOnOrganizationDop({
        search: null,
        offset: 0,
        limit: paginationDop.pageSize,
        organizationUuid,
        isSearch: true
      });
    }
  }, [
    isModal,
    getListNewsOnOrganization,
    pagination.pageSize,
    organizationUuid,
    getListNewsOnOrganizationDop,
    paginationDop.pageSize
  ]);

  const renderSearch = useMemo(() => (
    <Space style={{ marginBottom: 16 }} className={style.header}>
      { !isModal && <h2 style={{ marginBottom: 0 }}>Список новостей</h2> }
      <Input placeholder="Поиск"
             prefix={<img src="/images/search.svg" alt="Поиск" />}
             value={isModal ? search : searchDop}
             onChange={onChangeSearch}
      />
      { isModal && (
        <Button type="dashed"
                icon={modalColumns ? <RightOutlined /> : <LeftOutlined />}
                onClick={() => setModalColumns((value) => !value)}
        />
      ) }
    </Space>
  ), [search, onChangeSearch, isModal, modalColumns, searchDop]);

  return (
    <div className={style.containers}>
      { renderSearch }
      <div className="dd-table">
        { renderTable }
      </div>
    </div>
  );
};

export default TableNews;
