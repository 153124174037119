import { connect } from 'react-redux';
import ModalListNewsOnOrganization from './ModalListNewsOnOrganization';
import { closeModalNewsOnOrganization } from '../../store/news/actions';

const mapStateToProps = ({
  newsReducer
}) => ({
  isModalVisible: newsReducer.isModalVisible
});
const mapDispatchToProps = {
  closeModalNewsOnOrganization
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalListNewsOnOrganization);
