import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import {
 Upload, Button, Progress, Image, Typography, message
} from 'antd';
import { UploadOutlined, CloseOutlined } from '@ant-design/icons';

const { Text } = Typography;

const FileUploader = ({
                        apiUrl,
                        initialImageUrl = null,
                        initialImageAlt = 'Preview',
                        acceptedFileTypes = 'image/*',
                        maxFileSizeMB = 10,
                        onSuccess,
                        onError
                      }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(initialImageUrl);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSpeed, setUploadSpeed] = useState('0 KB/s');
  const [timeRemaining, setTimeRemaining] = useState('--');
  const [isUploading, setIsUploading] = useState(false);
  const cancelToken = useRef(null);
  const lastLoaded = useRef(0);
  const lastTime = useRef(0);

  // Сброс состояния при размонтировании
  useEffect(() => () => {
      if (cancelToken.current) {
        cancelToken.current.cancel();
      }
      if (previewImage && previewImage.startsWith('blob:')) {
        URL.revokeObjectURL(previewImage);
      }
    }, [previewImage]);

  // Обработка выбора файла
  useEffect(() => {
    if (selectedFile) {
      if (selectedFile.size > maxFileSizeMB * 1024 * 1024) {
        message.error(`Файл слишком большой. Максимальный размер: ${maxFileSizeMB}MB`);
        setSelectedFile(null);
        return;
      }

      if (selectedFile.type.startsWith('image/')) {
        const objectUrl = URL.createObjectURL(selectedFile);
        setPreviewImage(objectUrl);
      } else {
        setPreviewImage(null);
      }
    } else {
      setPreviewImage(initialImageUrl);
    }
  }, [selectedFile, initialImageUrl, maxFileSizeMB]);

  const calculateSpeedAndTime = (loaded, total, timeNow) => {
    if (lastTime.current && timeNow > lastTime.current) {
      const speed = (loaded - lastLoaded.current) / (timeNow - lastTime.current) / 1024;
      setUploadSpeed(`${speed > 1024 ? `${(speed / 1024).toFixed(1) } MB/s` : `${speed.toFixed(1) } KB/s`}`);

      if (speed > 0) {
        const remainingBytes = total - loaded;
        const seconds = remainingBytes / (speed * 1024);
        setTimeRemaining(seconds > 60
          ? `${Math.floor(seconds / 60)} мин ${Math.round(seconds % 60)} сек`
          : `${Math.round(seconds)} сек`);
      }
    }

    lastLoaded.current = loaded;
    lastTime.current = timeNow;
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      message.warning('Пожалуйста, выберите файл');
      return;
    }

    try {
      setIsUploading(true);
      setUploadProgress(0);
      lastLoaded.current = 0;
      lastTime.current = Date.now();

      // Получаем токен из localStorage или контекста
      const token = localStorage.getItem('Authorization');

      // Вариант 1: Если сервер принимает бинарные данные
      const response = await axios.put(apiUrl, selectedFile, {
        headers: {
          'Content-Type': selectedFile.type,
          Authorization: `Bearer ${token}`
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
          calculateSpeedAndTime(
            progressEvent.loaded,
            progressEvent.total,
            Date.now()
          );
        }
      });

      // Вариант 2: Если сервер требует Base64
      /*
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = async () => {
        const base64Data = reader.result.split(',')[1];
        const response = await axios.post(apiUrl, {
          image: base64Data,
          filename: selectedFile.name
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      };
      */

      message.success('Файл успешно загружен');
      if (onSuccess) onSuccess(response.data);
    } catch (error) {
      const errorMsg = error.response?.data?.message || 'Ошибка загрузки файла';
      message.error(errorMsg);
      if (onError) onError(error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleCancel = () => {
    if (cancelToken.current) {
      cancelToken.current.cancel('Загрузка отменена пользователем');
      setIsUploading(false);
      setUploadProgress(0);
      message.info('Загрузка отменена');
    }
  };

  const handleRemove = () => {
    if (isUploading) {
      handleCancel();
    }
    setSelectedFile(null);
    if (previewImage && previewImage.startsWith('blob:')) {
      URL.revokeObjectURL(previewImage);
    }
    setPreviewImage(initialImageUrl);
  };

  return (
    <div style={{
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 25
    }}>
      {/* Блок кнопок - ПЕРЕМЕЩЕН НАВЕРХ */}
      <div style={{
        display: 'flex',
        gap: 12,
        justifyContent: 'center',
        marginBottom: 16 // Добавляем отступ снизу
      }}>
        <Upload
          beforeUpload={(file) => {
            setSelectedFile(file);
            return false;
          }}
          maxCount={1}
          showUploadList={false}
          accept={acceptedFileTypes}
          disabled={isUploading}
        >
          <Button
            icon={<UploadOutlined />}
            size="large"
            style={{ width: 180 }}
          >
            Выбрать файл
          </Button>
        </Upload>

        <Button
          type="primary"
          size="large"
          onClick={handleUpload}
          disabled={!selectedFile || isUploading}
          loading={isUploading}
          style={{ width: 180 }}
        >
          {isUploading ? 'Загрузка...' : 'Загрузить'}
        </Button>

        {selectedFile && (
          <Button
            size="large"
            onClick={handleRemove}
            disabled={isUploading}
            style={{ width: 180 }}
          >
            Удалить
          </Button>
        )}

        {isUploading && (
          <Button
            danger
            size="large"
            icon={<CloseOutlined />}
            onClick={handleCancel}
            style={{ width: 180 }}
          >
            Отменить
          </Button>
        )}
      </div>

      {/* Область изображения - теперь идет ПОСЛЕ кнопок */}
      <div style={{
        position: 'relative',
        width: '100%',
        height: 400,
        marginTop: 10,
        backgroundColor: !previewImage ? '#f5f5f5' : 'transparent',
        borderRadius: 8,
        overflow: 'hidden'
      }}>
        {previewImage ? (
          <Image
            src={previewImage}
            alt={initialImageAlt}
            width="100%"
            height="100%"
            style={{
              objectFit: 'cover',
              display: 'block'
            }}
            preview={selectedFile ? {
              src: URL.createObjectURL(selectedFile)
            } : true}
          />
        ) : (
          <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Text type="secondary" style={{ fontSize: 16 }}>
              Изображение не выбрано
            </Text>
          </div>
        )}

        {/* Полоса прогресса */}
        {isUploading && (
          <div style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '12px 16px',
            backgroundColor: 'rgba(0, 0, 0, 0.65)',
            backdropFilter: 'blur(4px)',
            color: 'white'
          }}>
            <Progress
              percent={uploadProgress}
              status="active"
              strokeColor={{
                from: '#108ee9',
                to: '#87d068'
              }}
              showInfo={false}
              style={{ marginBottom: 8 }}
            />
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              color: 'rgba(255, 255, 255, 0.85)'
            }}>
              <Text style={{ color: 'inherit' }}>
                Прогресс:
                {' '}
                {uploadProgress}
                %
              </Text>
              <Text style={{ color: 'inherit' }}>{uploadSpeed}</Text>
              <Text style={{ color: 'inherit' }}>
                Осталось:
                {' '}
                {timeRemaining}
              </Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUploader;
