import * as actionsTypes from './types';

export function setSelectOrganizationOnNews(payload) {
  return {
    type: actionsTypes.SET_SELECT_ORGANIZATION_ON_NEWS,
    payload
  };
}

export function getListNewsOnOrganization(payload) {
  return {
    type: actionsTypes.GET_LIST_NEWS_ON_ORGANIZATION,
    payload
  };
}
export function getListNewsOnOrganizationSuccess(payload) {
  return {
    type: actionsTypes.GET_LIST_NEWS_ON_ORGANIZATION_SUCCESS,
    payload
  };
}

export function getListNewsOnOrganizationDop(payload) {
  return {
    type: actionsTypes.GET_LIST_NEWS_ON_ORGANIZATION_DOP,
    payload
  };
}
export function getListNewsOnOrganizationDopSuccess(payload) {
  return {
    type: actionsTypes.GET_LIST_NEWS_ON_ORGANIZATION_DOP_SUCCESS,
    payload
  };
}

export function createNewsOnOrganization(payload) {
  return {
    type: actionsTypes.CREATE_NEWS_ON_ORGANIZATION,
    payload
  };
}

export function editNewsOnOrganization(payload) {
  return {
    type: actionsTypes.EDIT_NEWS_ON_ORGANIZATION,
    payload
  };
}

export function deleteNewsOnOrganization(payload) {
  return {
    type: actionsTypes.DELETE_NEWS_ON_ORGANIZATION,
    payload
  };
}

export function publishNewsOnOrganization(payload) {
  return {
    type: actionsTypes.PUBLISH_NEWS_ON_ORGANIZATION,
    payload
  };
}

export function unpublishNewsOnOrganization(payload) {
  return {
    type: actionsTypes.UNPUBLISH_NEWS_ON_ORGANIZATION,
    payload
  };
}

export function openModalNewsOnOrganization(payload) {
  return {
    type: actionsTypes.OPEN_MODAL_NEWS_ON_ORGANIZATION,
    payload
  };
}

export function closeModalNewsOnOrganization(payload) {
  return {
    type: actionsTypes.CLOSE_MODAL_NEWS_ON_ORGANIZATION,
    payload
  };
}

export function openModalCreateNews(payload) {
  return {
    type: actionsTypes.OPEN_MODAL_CREATE_NEWS,
    payload
  };
}

export function closeModalCreateNews(payload) {
  return {
    type: actionsTypes.CLOSE_MODAL_CREATE_NEWS,
    payload
  };
}
