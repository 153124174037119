import * as actionTypes from './types';

const initialState = {
  selectOrganization: null,
  isModalVisible: false,
  isModalVisibleCreateNews: false,
  news: {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      search: null
    }
  },
  newsDop: {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      search: null
    }
  }
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_SELECT_ORGANIZATION_ON_NEWS:
      return {
        ...state,
        selectOrganization: payload
      };
    case actionTypes.GET_LIST_NEWS_ON_ORGANIZATION_SUCCESS:
      return {
        ...state,
        news: {
          ...state.news,
          data: payload.rows,
          pagination: {
            ...state.news.pagination,
            pageSize: payload.limit,
            current: payload.current,
            total: payload.count,
            search: payload.search
          }
        }
      };
    case actionTypes.GET_LIST_NEWS_ON_ORGANIZATION_DOP_SUCCESS:
      return {
        ...state,
        newsDop: {
          ...state.newsDop,
          data: payload.rows,
          pagination: {
            ...state.newsDop.pagination,
            pageSize: payload.limit,
            current: payload.current,
            total: payload.count,
            search: payload.search
          }
        }
      };
    case actionTypes.OPEN_MODAL_NEWS_ON_ORGANIZATION:
      return {
        ...state,
        isModalVisible: true
      };
    case actionTypes.CLOSE_MODAL_NEWS_ON_ORGANIZATION:
      return {
        ...state,
        isModalVisible: false
      };
    case actionTypes.OPEN_MODAL_CREATE_NEWS:
      return {
        ...state,
        isModalVisibleCreateNews: true
      };
    case actionTypes.CLOSE_MODAL_CREATE_NEWS:
      return {
        ...state,
        isModalVisibleCreateNews: false
      };
    default:
      return state;
  }
};

export default reducer;
