import { connect } from 'react-redux';
import NewsManagement from './NewsManagement';
import {
  getListOrganizations
} from '../../store/organization/actions';
import {
  setSelectOrganizationOnNews,
  openModalNewsOnOrganization,
  openModalCreateNews
} from '../../store/news/actions';

const mapStateToProps = ({
  organizationReducer: {
    listData
  },
  newsReducer: {
    selectOrganization
  },
  userReducer: {
    role
  }
}) => ({
  listData,
  selectOrganization,
  role
});
const mapDispatchToProps = {
  getListOrganizations,
  setSelectOrganizationOnNews,
  openModalNewsOnOrganization,
  openModalCreateNews
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsManagement);
