import { combineReducers } from 'redux';
import { authReducer } from './auth';
import { loaderReducer } from './loader';
import { userReducer } from './user';
import { organizationReducer } from './organization';
import { unassignedUsersReducer } from './unassignedUsers';
import { newsReducer } from './news';
import { voteReducer } from './vote';
import { personnelReducer } from './personnel';
import { eventReducer } from './event';
import { rolesReducer } from './roles';

const staticReducer = {
  authReducer,
  loaderReducer,
  userReducer,
  organizationReducer,
  unassignedUsersReducer,
  voteReducer,
  newsReducer,
  personnelReducer,
  eventReducer,
  rolesReducer
};

export default function createReducer(injectedReducers) {
  return combineReducers({
    ...staticReducer,
    ...injectedReducers
  });
}
