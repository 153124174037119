import { connect } from 'react-redux';
import ModalFormCreatingOrEditNews from './ModalFormCreatingOrEditNews';
import { closeModalCreateNews } from '../../store/news/actions';

const mapStateToProps = ({
  newsReducer: {
    isModalVisibleCreateNews
  }
}) => ({
  isModalVisibleCreateNews
});
const mapDispatchToProps = {
  closeModalCreateNews
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalFormCreatingOrEditNews);
