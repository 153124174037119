import { connect } from 'react-redux';
import TableNews from './TableNews';
import {
  getListNewsOnOrganization,
  getListNewsOnOrganizationDop
} from '../../store/news/actions';

const mapStateToProps = ({
  newsReducer
}) => ({
  data: newsReducer.news.data,
  pagination: newsReducer.news.pagination,
  dataDop: newsReducer.newsDop.data,
  paginationDop: newsReducer.newsDop.pagination,
  organizationUuid: newsReducer.selectOrganization
});
const mapDispatchToProps = {
  getListNewsOnOrganization,
  getListNewsOnOrganizationDop
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableNews);
