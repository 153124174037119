export const SET_SELECT_ORGANIZATION_ON_NEWS = 'SET_SELECT_ORGANIZATION_ON_NEWS';

export const GET_LIST_NEWS_ON_ORGANIZATION = 'GET_LIST_NEWS_ON_ORGANIZATION';
export const GET_LIST_NEWS_ON_ORGANIZATION_SUCCESS = 'GET_LIST_NEWS_ON_ORGANIZATION_SUCCESS';

export const GET_LIST_NEWS_ON_ORGANIZATION_DOP = 'GET_LIST_NEWS_ON_ORGANIZATION_DOP';
export const GET_LIST_NEWS_ON_ORGANIZATION_DOP_SUCCESS = 'GET_LIST_NEWS_ON_ORGANIZATION_DOP_SUCCESS';

export const CREATE_NEWS_ON_ORGANIZATION = 'CREATE_NEWS_ON_ORGANIZATION';

export const EDIT_NEWS_ON_ORGANIZATION = 'EDIT_NEWS_ON_ORGANIZATION';

export const DELETE_NEWS_ON_ORGANIZATION = 'DELETE_NEWS_ON_ORGANIZATION';
export const PUBLISH_NEWS_ON_ORGANIZATION = 'PUBLISH_NEWS_ON_ORGANIZATION';
export const UNPUBLISH_NEWS_ON_ORGANIZATION = 'UNPUBLISH_NEWS_ON_ORGANIZATION';

export const OPEN_MODAL_NEWS_ON_ORGANIZATION = 'OPEN_MODAL_NEWS_ON_ORGANIZATION';
export const CLOSE_MODAL_NEWS_ON_ORGANIZATION = 'CLOSE_MODAL_NEWS_ON_ORGANIZATION';

export const OPEN_MODAL_CREATE_NEWS = 'OPEN_MODAL_CREATE_NEWS';
export const CLOSE_MODAL_CREATE_NEWS = 'CLOSE_MODAL_CREATE_NEWS';
