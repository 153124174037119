import React, {
  useCallback,
  useEffect,
  useState,
  useMemo
} from 'react';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  DatePicker,
  Modal,
  Typography
} from 'antd';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import {
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  NotificationOutlined,
  NotificationTwoTone
} from '@ant-design/icons';
import { MaskedInput } from 'antd-mask-input';
import moment from 'moment';
import style from './formcreatingoreditnews.module.scss';
import { IMAGES_EVENTS } from '../../utils/url';
import { setDataEditEvent } from '../../store/event/actions';
import FileUploader from '../FileUploader/FileUploader';

const { Option } = Select;
const { confirm } = Modal;

const FormCreatingOrEditNews = ({
  createNewsOnOrganization,
  editNewsOnOrganization,
  deleteNewsOnOrganization,
  publishNewsOnOrganization, unpublishNewsOnOrganization,
  selectOrganization,
  closeModalCreateNews,
  isModal,
  isTable,
  record,
  isModalVisibleCreateNews,
  setKeyRowExpanded
}) => {
  const TOOLBAR_OPTIONS = [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['link'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],
    // ['emoji'],
    ['clean']
  ];
  const [quillValue, setQuillValue] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    if (record?.text) {
      setQuillValue(record.text);
    } else {
      setQuillValue('');
    }
  }, [record]);

  useEffect(() => {
    if (record?.uuid && form) {
      form.setFieldsValue({
        ...record.news,
        title: record.title,
        text: record.text,
        createdAt: record.createdAt && moment(record.createdAt),
        updatedAt: record.updatedAt && moment(record.updatedAt),
        publishedAt: record.publishedAt && moment(record.publishedAt),
        pictureUUID: record.pictureUUID
      });
    }
  }, [isModal, record, form]);

  useEffect(() => {
    if (!isModalVisibleCreateNews && !record?.uuid) {
      form.setFieldsValue({});
      form.resetFields();
    }
  }, [form, isModalVisibleCreateNews, record]);

  const deleteModal = useCallback(() => {
    confirm({
      title: 'Удалить новость?',
      icon: <ExclamationCircleOutlined />,
      content: `${record?.title}`,
      okText: 'Да',
      okType: 'danger',
      cancelText: 'Нет',
      onOk() {
        deleteNewsOnOrganization({
          organizationUuid: selectOrganization,
          newsUuid: record?.uuid
        });
      }
    });
  }, [
    deleteNewsOnOrganization,
    record,
    selectOrganization
  ]);

  const publishModal = useCallback(() => {
    confirm({
      title: 'Опубликовать новость?',
      icon: <NotificationOutlined />,
      content: `${record?.title}`,
      okText: 'Да',
      okType: 'danger',
      cancelText: 'Нет',
      onOk() {
        publishNewsOnOrganization({
          organizationUuid: selectOrganization,
          newsUuid: record?.uuid
        });
      }
    });
  }, [
    publishNewsOnOrganization,
    record,
    selectOrganization
  ]);

  const unpublishModal = useCallback(() => {
    confirm({
      title: 'Снять новость с публикации?',
      icon: <EyeInvisibleOutlined />,
      content: `${record?.title}`,
      okText: 'Да',
      okType: 'danger',
      cancelText: 'Нет',
      onOk() {
        unpublishNewsOnOrganization({
          organizationUuid: selectOrganization,
          newsUuid: record?.uuid
        });
      }
    });
  }, [
    unpublishNewsOnOrganization,
    record,
    selectOrganization
  ]);

  const handleFormSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        if (isModal) {
          createNewsOnOrganization({
            ...values,
            title: values.title && values.title.valueOf(),
            text: quillValue,
            organizationUuid: selectOrganization
          });
        } else {
          editNewsOnOrganization({
            ...values,
            title: values.title && values.title.valueOf(),
            text: quillValue,
            organizationUuid: selectOrganization,
            newsUuid: record.uuid,
            setKeyRowExpanded
          });
        }
      })
      .catch((errorInfo) => {

      });
  }, [
    isModal,
    form,
    quillValue,
    record,
    createNewsOnOrganization,
    editNewsOnOrganization,
    selectOrganization,
    setKeyRowExpanded
  ]);

  const closeModal = useCallback(() => {
    if (isModal) {
      closeModalCreateNews();
    } else {
      setKeyRowExpanded((values) => values.filter((item) => item !== record?.uuid));
    }
  }, [closeModalCreateNews, isModal, setKeyRowExpanded, record]);

  return (
    <Form name="basic"
          layout="vertical"
          form={form}
          autoComplete="off"
          requiredMark={false}
    >
      <Row gutter={[40, 8]}>
        <Col md={24} lg={12}>
          <Form.Item label="Заголовок"
                     name="title"
                     rules={[
                       {
                         required: true,
                         message: 'Введите заголовок новости'
                       }
                     ]}
          >
            <Input placeholder="Введите заголовок новости" />
          </Form.Item>
          <Form.Item label={'Текст новости'}>
            <ReactQuill
              value={quillValue}
              onChange={setQuillValue} // Обновляем локальное состояние
              style={{ height: '357px' }}
              placeholder="Введите текст новости..."
              modules={{
                toolbar: {
                  container: TOOLBAR_OPTIONS
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col md={24} lg={12}>
          <Form.Item label="Картинка новости" rules={[{ required: false }]}>
            {record?.uuid && (
              <FileUploader
                apiUrl={`/api/web/v1/news/${selectOrganization}/${record.uuid}/picture`}
                initialImageUrl={record.pictureUUID
                  ? `api/pictures/v1/picture/${record.pictureUUID}`
                  : '/pictures/eventsProperty-211.svg'}
                initialImageAlt="Картинка новости"
                acceptedFileTypes="image/png, image/jpeg"
                maxFileSizeMB={50}
                onSuccess={(data) => console.log('Upload success:', data)}
                onError={(error) => console.error('Upload error:', error)}
              />
            )}
            {!record?.uuid && (
              <h3 style={{ color: 'grey', marginTop: '10px' }}>
                Картинку можно будет добавить после сохранения новости
              </h3>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]} justify="space-between" align="bottom" className={style.button}>
        <Col md={24} lg={12}>
          {record?.uuid && (
          <Row gutter={[8, 8]}>
            <Col md={10}>
              <Form.Item>
                {!record?.publishedAt && (
                <Button block
                        onClick={publishModal}
                >
                  Опубликовать новость
                </Button>
                  )}
                {record?.publishedAt && (
                  <Button block
                          onClick={unpublishModal}
                  >
                    Снять с публикации
                  </Button>
                )}
              </Form.Item>
            </Col>
            <Col md={10}>
              <Form.Item>
                <Button block
                        onClick={deleteModal}
                >
                  Удалить новость
                </Button>
              </Form.Item>
            </Col>
          </Row>
            )}
        </Col>
        <Col md={24} lg={12}>
          <Row gutter={[8, 8]} justify="end" >
            <Col md={10}>
              <Form.Item>
                <Button block
                        onClick={closeModal}
                >
                  Отмена
                </Button>
              </Form.Item>
            </Col>
            <Col md={10}>
              <Form.Item>
                <Button type="primary"
                        htmlType="submit"
                        onClick={handleFormSubmit}
                        block
                >
                  Сохранить
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default React.memo(FormCreatingOrEditNews);
