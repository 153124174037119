import React, { useCallback } from 'react';
import {
  Modal
} from 'antd';
import style from './ModalFormCreatingOrEditNews.module.scss';
import FormCreatingOrEditNews from '../FormCreatingOrEditNews';

const ModalFormCreatingOrEditNews = ({
  isModalVisibleCreateNews,
  closeModalCreateNews
}) => {
  const handleCancel = useCallback(() => {
    closeModalCreateNews();
  }, [closeModalCreateNews]);

  return (
    <Modal visible={isModalVisibleCreateNews}
           title="Создать новость"
           onCancel={handleCancel}
           wrapClassName={style.modal}
           footer={null}
    >
      <FormCreatingOrEditNews isModal />
    </Modal>
  );
};

export default ModalFormCreatingOrEditNews;
