import React, { useCallback, useEffect } from 'react';
import {
  Button,
  Row,
  Space,
  Select,
  Col,
  Upload
} from 'antd';
import style from './newsmanagement.module.scss';
import TableNews from '../../components/TableNews';
import ModalListNewsOnOrganization
  from '../../components/ModalListNewsOnOrganization';
import ModalFormCreatingOrEditNews
  from '../../components/ModalFormCreatingOrEditNews';
import ModalFormCreatingOrEditPerson from '../../components/ModalFormCreatingOrEditPerson';

const NewsManagement = ({
  listData,
  selectOrganization,
  getListOrganizations,
  setSelectOrganizationOnNews,
  openModalNewsOnOrganization,
  openModalCreateNews
}) => {
  useEffect(() => {
    getListOrganizations();
  }, [getListOrganizations]);

  const onChangeOrganize = useCallback((value) => {
    setSelectOrganizationOnNews(value);
  }, [setSelectOrganizationOnNews]);

  return (
    <div className="dd-container">
      <div className={`dd-container-main ${style.containers}`}>
        <div className={style.header}>
          <h2>Управление новостями</h2>
          <Row gutter={[20, 8]}>
            <Col flex="auto" style={{ maxWidth: 500 }}>
              <Select style={{ width: '100%' }}
                      placeholder="Выберите организацию"
                      fieldNames={{ label: 'title', value: 'uuid' }}
                      options={listData}
                      onChange={onChangeOrganize}
                      value={selectOrganization}
              />
            </Col>
            <Col flex="300px">
              <Space size={20}>
                <Button onClick={openModalCreateNews}
                        disabled={!selectOrganization}
                >
                  Добавить новость
                </Button>
                <ModalFormCreatingOrEditNews />
              </Space>
            </Col>
          </Row>
        </div>
        <div className={style.main}>
          { !selectOrganization && <h2>Список новостей</h2> }
          { selectOrganization && <TableNews key="table_news_3" /> }
        </div>
        <div className={style.footer}>
          { selectOrganization && (
            <>
              <Button onClick={openModalNewsOnOrganization}>
                Развернуть / Редактировать таблицу
              </Button>
              <ModalListNewsOnOrganization key="ModalListNewsOnOrganization_1" />
            </>
          ) }

        </div>
      </div>
    </div>
  );
};

export default NewsManagement;
