import React, { useCallback } from 'react';
import {
  Button,
  Modal
} from 'antd';
import style from './modallistnewsonorganization.module.scss';
import TableNews from '../TableNews';

const ModalListNewsOnOrganization = ({
  isModalVisible,
  closeModalNewsOnOrganization
}) => {
  const handleCancel = useCallback(() => {
    closeModalNewsOnOrganization();
  }, [closeModalNewsOnOrganization]);
  return isModalVisible && (
    <Modal visible={isModalVisible}
           closable={false}
           footer={[
             <Button onClick={handleCancel}>
               Закрыть
             </Button>
           ]}
           wrapClassName={style.modal}
    >
      <TableNews isModal isModalVisible={isModalVisible} key="table_news_2" />
    </Modal>
  );
};

export default React.memo(ModalListNewsOnOrganization);
